<script>
  import { Tabs, Tab, TabList, TabPanel } from "svelte-tabs";
  import Categories from "./Categories.svelte";
  import Category from "./Category.svelte";
  import Products from "./Products.svelte";
  import Popup from "./Popup.svelte";
  import Attributes from "./Attributes.svelte";

  let query, currentCategory;

  const openCategory = async ({ detail: category }) => {
    currentCategory = category;
    query = new Promise(async (res, rej) => {
      try {
        const response = await fetch(
          `/wp-json/product-filter/v1/products?cat=${currentCategory.id}`,
        );
        const json = await response.json();
        res(json);
      } catch (e) {
        rej(e);
      }
    });
  };

  const onAttributesChange = ({ detail: attributes }) => {
    let currentAttributes = "";
    attributes.forEach((attribute) => {
      let currentTerms = "";
      attribute.terms.forEach((term) => {
        if (term.checked) currentTerms += `${term.id},`;
      });
      if (currentTerms) {
        currentTerms = currentTerms.slice(0, -1);
        currentAttributes += `${attribute.id}$${currentTerms}|`;
      }
    });
    if (currentAttributes) {
      currentAttributes = currentAttributes.slice(0, -1);
    }
    let url = `/wp-json/product-filter/v1/products?cat=${currentCategory.id}`;
    if (currentAttributes) url += `&atts=${currentAttributes}`;

    query = new Promise(async (res, rej) => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        res(json);
      } catch (e) {
        rej(e);
      }
    });
  };

  const onPopupClose = () => {
    query = null;
    currentCategory = null;
  };
</script>

<section class="product-filter">
  <h1 class="title">Elegí tu notebook ideal</h1>
  <Tabs>
    <TabList>
      <Tab>Por marca</Tab>
      <Tab>Por procesador</Tab>
      <Tab>Por uso</Tab>
    </TabList>
    <TabPanel>
      <Categories>
        <Category
          name="Lenovo"
          id="354"
          img="/wp-content/uploads/2025/03/web-03.png"
          on:click={openCategory}
        />
        <Category
          name="Acer"
          id="359"
          img="/wp-content/uploads/2025/03/logo-07.png"
          on:click={openCategory}
        />
        <Category
          name="Asus"
          id="346"
          img="/wp-content/uploads/2025/03/web-04.png"
          on:click={openCategory}
        />

        <Category
          name="Dell"
          id="353"
          img="/wp-content/uploads/2025/03/web-05.png"
          on:click={openCategory}
        />
        <Category
          name="HP"
          id="355"
          img="/wp-content/uploads/2025/03/web_Mesa-de-trabajo-1.png"
          on:click={openCategory}
        />
        <Category
          name="Msi"
          id="427"
          img="/wp-content/uploads/2025/03/logo-06.png"
          on:click={openCategory}
        />
      </Categories>
    </TabPanel>
    <TabPanel>
      <Categories>
        <Category
          name="Core i3"
          id="1579"
          img="/wp-content/uploads/2025/03/web-06.png"
          on:click={openCategory}
        />
        <Category
          name="Core i5"
          id="1580"
          img="/wp-content/uploads/2025/03/web-07.png"
          on:click={openCategory}
        />
        <Category
          name="Core i7"
          id="1581"
          img="/wp-content/uploads/2025/03/web-08.png"
          on:click={openCategory}
        />
        <Category
          name="Ryzen 3"
          id="1584"
          img="/wp-content/uploads/2025/03/web-09.png"
          on:click={openCategory}
        />
        <Category
          name="Ryzen 5"
          id="1585"
          img="/wp-content/uploads/2025/03/web-10.png"
          on:click={openCategory}
        />
        <Category
          name="Ryzen 7"
          id="1586"
          img="/wp-content/uploads/2025/03/web-11.png"
          on:click={openCategory}
        />
      </Categories>
    </TabPanel>
    <TabPanel>
      <Categories>
        <Category
          name="Gaming"
          id="349"
          img="/wp-content/uploads/2025/03/1.png"
          on:click={openCategory}
        />
        <Category
          name="Diseño y Creatividad"
          id="343"
          img="/wp-content/uploads/2025/03/4.png"
          on:click={openCategory}
        />
        <Category
          name="Trabajo y Oficina"
          id="419"
          img="/wp-content/uploads/2025/03/5.png"
          on:click={openCategory}
        />
        <Category
          name="Arquitectura"
          id="342"
          img="/wp-content/uploads/2025/03/2.png"
          on:click={openCategory}
        />
        <Category
          name="Hogar"
          id="364"
          img="/wp-content/uploads/2025/03/3.png"
          on:click={openCategory}
        />
      </Categories>
    </TabPanel>
  </Tabs>
  {#if currentCategory && query}
    <Popup
      title={currentCategory.name}
      icon={currentCategory.img}
      on:close={onPopupClose}
    >
      <Attributes on:change={onAttributesChange} />
      <Products {query} />
    </Popup>
  {/if}
</section>

<style>
  .title {
    text-transform: uppercase;
    font-size: 1.25rem;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid #2279d3;
    font-weight: bold;
    display: inline-block;
  }
  :global(.svelte-tabs .svelte-tabs__tab) {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 500;
  }
  :global(.svelte-tabs .svelte-tabs__selected) {
    border-bottom-color: #2279d3;
    color: #2279d3;
  }
</style>
